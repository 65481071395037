// grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;


//global
$white: #ffffff;
$black: #000000;

//body
$grad-color-t: #7f3c73;
$grad-color-b: #50158d;

//cards
$card-title: #411273;
$card-link: #0db9fa;
$selected: #d67f33;
$waterFColor: #4d6de3;
$waterBColor: #c7eeff;
$progressColor: #ffb61f;

//overlays
$bottombutton: #24a7ea;
