//includes
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700&subset=latin-ext');
@import 'vars';
@import 'mixin';
@import 'breakpoints';

html {
    min-height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    background: $grad-color-t;
    background-repeat: no-repeat;
    background-attachment: fixed !important;
    background: -moz-linear-gradient(top, $grad-color-t 0%, $grad-color-b 40%, $grad-color-b 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $grad-color-t), color-stop(40%, $grad-color-b), color-stop(100%, $grad-color-b));
    background: -webkit-linear-gradient(top, $grad-color-t 0%, $grad-color-b 40%, $grad-color-b 100%);
    background: -o-linear-gradient(top, $grad-color-t 0%, $grad-color-b 40%, $grad-color-b 100%);
    background: -ms-linear-gradient(top, $grad-color-t 0%, $grad-color-b 40%, $grad-color-b 100%);
    background: linear-gradient(to bottom, $grad-color-t 0%, $grad-color-b 40%, $grad-color-b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grad-color-t', endColorstr='$grad-color-b', GradientType=0 );
}
.visible-logo-link{
    display: inline-block;
    background: rgba(0,0,0,0);
    border-radius: 50%;
    width: 128px;
    height: 128px;
    position: relative;
    left: 50px;
    top: -40px;
    z-index: 9999999;
}
/* myModal*/
.sartname-btn-wrapper{
    height: 60px;
    padding: 15px 0 0 0;
    text-align: center;
    a{
        font-size: .9rem;
        color: rgba(255,255,255, .7) !important;
    }
}

#myModal{
    z-index: 99999999999 !important; 
    color: black;
    font-size: 1rem;
}
.modal-backdrop.show {
    opacity: 0.25 !important;
}
/* end myModal */
// .row-flipclock {
//    zoom: 0.5;
//    -moz-transform: scale(0.5)
   
// }
.navbar-toggler {
    @include media-breakpoint-down(md) {
        top: -30px;
        position: relative;
    }
}
nav #navbarMain{
    margin: 0em !important;
    @include media-breakpoint-down(md) {
        margin-top: -1.5em;
    }   
}
.navbar-collapse{
    @include media-breakpoint-down(sm) {
        background: rgba(124,60,115, 1);
    }
    .navbar-nav {
        @include media-breakpoint-down(sm) {
            padding: 20px;
        }   
    }
}
#bodywrap {
    min-height: 100%;
}

nav {
    z-index: 9999999 !important;
    #navbarMain {
        margin-top: 1.5em;
        margin-right: 1em;
        .nav-item {
            font-weight: bold;
        }
    }
}

.topoverlay {
    position: fixed;
    width: 100%;
    height: 13em;
    z-index: 10;
    background: linear-gradient(to bottom, rgba(125,64,113, 1), rgba(125,64,113, .9), rgba(125,64,113, 0));
    // background-repeat: repeat-x;
}

#contentwrap {
    font-family: 'Montserrat', sans-serif;
    color: $white;
    &:before {
        content: "";
        display: inline-block;
        width: 40em;
        height: 15em;
        background-image: url("../gfx/logos/logo.png");
        background-repeat: no-repeat;
        background-size: 40em auto;
        background-position: fixed;
        position: fixed;
        margin-left: -15em;
        top: 0;
        z-index: 99999;
        @include media-breakpoint-down(sm) {
            background-size: 30em auto;
            margin-left: -12em;
        }
    }
}

#welcome {
    height: 100vh;
    @include media-breakpoint-down(sm) {
        height: 100% !important;
        overflow: visible;
    }
    padding-top: 12em;
    // display: flex;
    // flex-direction: column;
    h2 {
        margin-bottom: 2em;
    }
    a {
        color: $selected;
    }
    .enter {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        // margin-top: auto;
        // padding-bottom: 3em;
        a {
            color: $card-link;
            &:hover {
                text-decoration: none;
                color: $white;
            }
        }
    }
}

#cards {
    padding-top: 12em;
    margin-bottom: 12em;
    .alert-primary {
        background-color: rgba(0,191,243,.5);
        border-color: rgba(0,191,243,.5);
        color: $white;
        font-size: .87em;
        margin: 3em 15px 3em 15px;
        i {
            color: $grad-color-t;
            margin-right: .5em;
        }
    }
    .card {
        color: $black;
        padding: .5em;
        min-height: 20em;
        -webkit-box-shadow: 1em 1em 2em 0 rgba(0,0,0,0.5);
        -moz-box-shadow: 1em 1em 2em 0 rgba(0,0,0,0.5);
        box-shadow: 1em 1em 2em 0 rgba(0,0,0,0.5);
        @include media-breakpoint-down(sm) {
            margin-top: 1em;
        }
        a { //whole div link
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left: 0;
            z-index: 1;
            background-image: url('../gfx/blank.gif');
        }
        .card-img-top {
            max-height: 187px;
            /* position: absolute;
            clip: rect(0px,332px,187px,0px); */
        }
        .card-body {
            padding: 1em .1em;
        }
        .card-title {
            text-align: center;
            color: $card-title;
            font-weight: bold;
        }
        + .row {
            margin-top: 1.5em;
            /* margin-bottom: 3em; */
            padding: 0 1em;

            .col {
                img {
                    margin-left: .3em;
                    max-height: 34px;
                    width: auto;
                }
                &:nth-of-type(2) {
                    text-align: right !important;
                }

            }
        }

    }
    .vote {
        min-width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
        padding: .1em .5em 1em .5em;
        .col {
            max-width: 50% !important;
            float: left;
            img {
                margin-left: .3em;
                max-height: 33px;
                width: auto;
            }
            &:nth-of-type(2) {
                text-align: right !important;
            }
        }
    }
    .progress {
        height: 2em;
        margin-bottom: 1em;
        .progress-bar {
            background: $progressColor;
            color: $black;
        }
    }

    .selected {
        box-shadow: 0 0 .3em .3em $selected;
    }
    // .free {
    //     box-shadow: 0 0 .3em .3em $waterBColor
    // }
    .voter {
        a {
            color: $card-link;
            &:hover {
                text-decoration: none;
                color: $white;
            }
        }
    }

}

#project {
    padding-top: 14em;
    margin-bottom: 12em;
    h2 {
        margin-bottom: 2em;
    }
    img {
        margin-top: 1em;
        margin-bottom: 1em;
    }
    a {
        color: $selected;
        //text-decoration: underline;
    }
    .sharethis-inline-share-buttons {
        margin-top: 3em;
    }
    .voltron {
        margin-top: 0em;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding:0;
        .blackoverlay {
            min-height: 400px;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0);
            /* border-radius: .25em; */
            h1,h2 {
                text-shadow: 2px 2px rgba(0,0,0,0.75);
            }
            h2 {
                margin-bottom:0;
            }
        }
    }
}
.jumbotron {
    border-radius: 0 !important;
}
.embed-responsive-16by9 {
    margin-bottom: 1em;
}
#sendselection {
    -webkit-transition: all 0.5s ease;
       -moz-transition: all 0.5s ease;
         -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
    z-index: 99;
    width: 100%;
    height: 8em;
    
    position: fixed;
    top: auto;
    bottom: 0;
    
}
#gondertext {
    font-size: 1.2em;
    font-weight: 700;
    margin-right: 1em;
}
.sendselectionblue {
    background: rgba($bottombutton, .9);
    #gondertext {
        color: $white !important;
    }

    button {
        background: transparent;
        border-radius: 0;
        background: $white;
    }
}

.sendselectionwhite {
    background: rgba($white, .9);
    #gondertext {
        color: $black !important;
    }

    button {
        background: transparent;
        border-radius: 0;
        background: $bottombutton;
    }
}

#selected {
    text-align: center;
    p {
        color: $white;
    }
    button {
        width: 80%;
        margin: 1.5em 0;
        padding: 1em;
    }
    .modal-content {
        background: transparent !important;
        border: none;
    }
}

.modal-backdrop.show {
    opacity: 0.85;
}

//Share Buttons 
// #st-2 {
//     top: 310px !important;
// }

#last {
    border-top: 1px solid rgba(255,255,255,0.3);;
    border-bottom: 1px solid rgba(255,255,255,0.3);;
    padding: 3em 0;
    margin-bottom: 2em;
    .win {
        margin-bottom: 2em;
        h4 {
            font-size: 1.2em;
            min-height: 3em;
            padding: .3em;
            text-align: center;
            background : rgba(0,0,0,0.3);
            vertical-align: middle;
        }
        img {
            margin: 0 0 1em 0;
        }
        p {
            font-size: .8em;
            text-align: justify;
        }
    }
}
